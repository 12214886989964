<div class="plan-card">
  <div class="plan-card__image">
    <div>
      <span>
        {{ flag }}
      </span>
    </div>
    <img [src]="photo" alt="Foto do plano">
  </div>
  <div class="plan-card__content">
    <div class="plan-card__content__text">
      <p>{{ title }}</p>
      <h5>R${{ price }}</h5>
    </div>
    <button>
      {{ buttonText || 'Quero ser um profissional' }}
    </button>
  </div>
</div>
