import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-medium-card',
  imports: [CommonModule],
  templateUrl: './medium-card.component.html',
  styleUrl: './medium-card.component.scss'
})
export class MediumCardComponent {
    @Input()
    image: string = '';

    @Input()
    imageAlt: string = '';

    @Input()
    title: string = '';
}
