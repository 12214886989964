<main class="profissionais-certificados">
  <section class="profissionais-certificados__container">
    <div class="profissionais-certificados__header">
      <h1>Busque por profissionais certificados em sua região</h1>
      <p>Precisando agilizar a regularização de suas obras?</p>
      <p>Encontre um profissional com o selo Master HUB para lhe ajudar.</p>

      <div class="profissionais-certificados__search">
        <input type="text" (input)="searchProfessional($event)" />
        <button (click)="handleFilter()">Buscar</button>
      </div>
    </div>

    <div class="profissionais-certificados__cards">
      @if (filteredProfessionals.length > 0) {
        <app-professional-card
          *ngFor="let professional of filteredProfessionals"
          [professional]="professional"
        />
      } @else {
        <p>Nenhum profissional encontrado</p>
        <a href="profissionais-certificados">Voltar</a>
      }
    </div>

    <div class="profissionais-certificados__cta">
      <h2>Seja você também um profissional Master Hub</h2>
      <p>Torne-se já um especialista em regulamentação de obras</p>
      <a routerLink="/cadastro/profissional">Cadastre-se</a>
    </div>
  </section>
</main>
