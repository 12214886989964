import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ComponentsModule } from "../../components/components.module";

@Component({
  imports: [ComponentsModule, CommonModule],
  templateUrl: './seja-um-profissional.component.html',
  styleUrl: './seja-um-profissional.component.scss'
})
export class SejaUmProfissionalComponent {
  advantages = [
    {
      image: 'images/seja-um-profissional/advantages/1.png',
      alt: 'Vantagem 1',
      text: 'Modelo de Negócio Testado Franquias oferecem um modelo de negócio já validado, com processos e serviços padronizados, reduzindo os riscos de erros.'
    },
    {
      image: 'images/seja-um-profissional/advantages/2.png',
      alt: 'Vantagem 2',
      text: 'Reconhecimento de Marca A franquia já possui uma marca consolidada no mercado, o que atrai mais clientes e gera maior confiança no serviço prestado.'
    },
    {
      image: 'images/seja-um-profissional/advantages/3.png',
      alt: 'Vantagem 3',
      text: 'Acesso a Tecnologias e Ferramentas Franquias geralmente oferecem softwares e sistemas exclusivos para regulamentação e gestão de obras, otimizando o trabalho.'
    },
    {
      image: 'images/seja-um-profissional/advantages/4.png',
      alt: 'Vantagem 4',
      text: 'Reconhecimento de Marca A franquia já possui uma marca consolidada no mercado, o que atrai mais clientes e gera maior confiança no serviço prestado.'
    },
    {
      image: 'images/seja-um-profissional/advantages/5.png',
      alt: 'Vantagem 5',
      text: 'Marketing Estruturado O franqueado se beneficia de campanhas de marketing nacionais, economizando em publicidade e ampliando sua visibilidade.'
    },
  ];

  feedbacks = [
    {
      photo: 'images/seja-um-profissional/avatars/avatar.png',
      name: 'jose',
      feedback: 'Melhor plataforma que pude trabalhar.'
    },
    {
      photo: 'images/seja-um-profissional/avatars/avatar-1.png',
      name: 'Josélio',
      feedback: 'Uma ótima oportunidade para de desenvolver'
    },
    {
      photo: 'images/seja-um-profissional/avatars/avatar-2.png',
      name: 'Roberto',
      feedback: 'Uma ótima oportunidade para de desenvolver'
    },
    {
      photo: 'images/seja-um-profissional/avatars/avatar-3.png',
      name: 'Jéssica',
      feedback: 'Plataforma com um excelente suporte.'
    },
    {
      photo: 'images/seja-um-profissional/avatars/avatar-4.png',
      name: 'Bia',
      feedback: 'Sensacional plataforma de aprendizado.'
    },
  ];

  plans = [
    {
      photo: 'images/seja-um-profissional/plans/default.png',
      title: 'Plano 1 mensal',
      price: 19.99,
      flag: 'Promoção',
    },
    {
      photo: 'images/seja-um-profissional/plans/default.png',
      title: 'Plano 1 mensal',
      price: 19.99,
      flag: 'Promoção',
    },
    {
      photo: 'images/seja-um-profissional/plans/default.png',
      title: 'Plano 1 mensal',
      price: 19.99,
      flag: 'Promoção',
    },
    {
      photo: 'images/seja-um-profissional/plans/default.png',
      title: 'Plano 1 mensal',
      price: 19.99,
      flag: 'Promoção',
    },
  ];

  faqs = [
    {
      question: 'Quais são os principais serviços oferecidos pela franquia?',
      answer: 'Nossos serviços incluem construção, reformas residenciais e comerciais, gerenciamento de obras, consultoria técnica, além de serviços de manutenção predial e acabamentos.'
    },
    {
      question: 'Quem pode se tornar um franqueado?',
      answer: 'Qualquer pessoa com perfil empreendedor, experiência em gestão e interesse no setor de construção civil pode se candidatar a uma franquia. Não é obrigatório ter experiência técnica no setor, pois oferecemos treinamentos completos.'
    },
    {
      question: 'Como funciona o suporte ao franqueado?',
      answer: 'Oferecemos suporte contínuo em várias áreas, incluindo marketing, operação, gerenciamento de obras, treinamentos técnicos e administrativos, além de acesso a um sistema de gestão exclusivo.'
    },
    {
      question: 'Quanto tempo demora para abrir a franquia após a assinatura do contrato?',
      answer: 'O prazo para abertura pode variar dependendo da estrutura necessária e das regulamentações locais, mas geralmente leva de 3 a 6 meses após a assinatura do contrato.'
    }
  ];
}
