import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ComponentsModule } from "../../components/components.module";

@Component({
  imports: [CommonModule, ComponentsModule],
  templateUrl: './cursos.component.html',
  styleUrl: './cursos.component.scss'
})
export class CursosComponent {
  courses = [
    {
      photo: 'images/courses/cards/Base.png',
      name: 'Gestão de obras públicas',
      description: 'Este curso aborda o planejamento e controle de obras, desde a preparação do cronograma até a gestão de recursos humanos e materiais, garantindo prazos e orçamento.',
      hours: 30,
    },
    {
      photo: 'images/courses/cards/Base-1.png',
      name: 'Técnicas de construção civil',
      description: 'Focado nas principais técnicas, boas práticas e métodos construtivos, ensina como utilizar materiais de forma eficiente e trabalhar com estruturas, acabamentos e fundações.',
      hours: 30,
    },
    {
      photo: 'images/courses/cards/Base-2.png',
      name: 'Orçamento e Planejamento',
      description: 'Ensina a calcular e planejar os custos de uma obra, abrangendo aqui desde a aquisição de materiais até o gerenciamento de custos indiretos e sua margem de lucro.',
      hours: 30,
    },
    {
      photo: 'images/courses/cards/Base-3.png',
      name: 'Segurança do Trabalho em Obras',
      description: 'O curso trata das normas e práticas de segurança aplicáveis no ambiente de construção civil, prevenindo acidentes e garantindo um ambiente de trabalho seguro organizado..',
      hours: 30,
    },
    {
      photo: 'images/courses/cards/Base-4.png',
      name: 'Interpretação de Projetos',
      description: 'O curso trata das normas e práticas de segurança aplicáveis no ambiente de construção civil, prevenindo acidentes e garantindo um ambiente de trabalho seguro organizado..',
      hours: 30,
    }
  ];

  plans = [
    {
      photo: 'images/courses/plans/image.png',
      name: 'Plano 3',
      price: 29.99,
      flag: 'Oferta limitada'
    },
    {
      photo: 'images/courses/plans/image-1.png',
      name: 'Plano 3',
      price: 39.99,
      flag: 'Mais vendido'
    },
    {
      photo: 'images/courses/plans/image-2.png',
      name: 'Plano 3',
      price: 59.99,
      flag: 'Para sua empresa'
    },
    {
      photo: 'images/courses/plans/image-3.png',
      name: 'Plano 3',
      price: 59.99,
      flag: 'Para sua empresa'
    },
  ];
}
