import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CourseCardComponent } from './card/course-card/course-card.component';
import { FeedbackCardComponent } from './card/feedback-card/feedback-card.component';
import { MediumCardComponent } from './card/medium-card/medium-card.component';
import { PlanCardComponent } from './card/plan-card/plan-card.component';
import { ProfessionalCardComponent } from './card/professional-card/professional-card.component';
import { SmallCardComponent } from './card/small-card/small-card.component';
import { SharedModule } from './shared/shared.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    SmallCardComponent,
    CourseCardComponent,
    ProfessionalCardComponent,
    MediumCardComponent,
    FeedbackCardComponent,
    PlanCardComponent
  ],
  exports: [
    SharedModule,
    SmallCardComponent,
    CourseCardComponent,
    ProfessionalCardComponent,
    MediumCardComponent,
    FeedbackCardComponent,
    PlanCardComponent
  ]
})
export class ComponentsModule { }
