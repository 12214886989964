import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-feedback-card',
  imports: [],
  templateUrl: './feedback-card.component.html',
  styleUrl: './feedback-card.component.scss'
})
export class FeedbackCardComponent {
  @Input() photo: string = '';
  @Input() name: string = '';
  @Input() feedback: string = '';
}
