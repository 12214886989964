import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-footer',
  imports: [CommonModule, RouterModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  variant: "normal" | "dark" | "white" = "normal";

  footerLinks = [
    [
      { section: 'MasterHub' },
      { label: 'Sobre nós', link: '/sobre' },
      { label: 'FAQ', link: '/faq' },
      { label: 'Contato', link: '/contato' },
    ],
    [
      { section: 'Profissional' },
      { label: 'Seja um profissional', link: '/seja-um-profissional' },
      { label: 'Cadastre-se', link: '/cadastro/profissional' },
      { label: 'Fazer login', link: '/login' }
    ],
    [
      { section: 'Cursista' },
      { label: 'Cursos', link: '/cursos' },
      { label: 'Profissionais certificados', link: '/certificados' },
      { label: 'Cadastre-se', link: '/cadastro' },
      { label: 'Fazer login', link: '/login' }
    ]
  ]
}
