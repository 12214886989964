<main class="seja-um-profissional">
  <section class="banner-container">
    <div class="banner__pattern">
      <img src="images/seja-um-profissional/banner-pattern.png" alt="Background Pattern">
    </div>
    <div class="banner__carousel">
      <img src="images/seja-um-profissional/carrosel-1.png" alt="Homens trabalhando">
    </div>
  </section>

  <div class="seja-um-profissional__logo">
    <img src="images/seja-um-profissional/masterhub-logo.png" alt="MaterHub Logo">
  </div>

  <div class="seja-um-profissional__content">
    <section class="seja-um-profissional__introduction">
      <div class="seja-um-profissional__introduction__content">
        <h3>Seja um profissional</h3>
        <p>
          Entender para atender, esse é o nosso jeito Mastergeo de pensar e atuar. É o que nos impulsiona a criar soluções para nossos clientes.
          Somos uma empresa atuante na área de engenharia, existente há 15 anos, voltada para projetos engenharia, arquitetura, infraestrutura,
          topografia dentre outros diversos serviços, que estão relacionados logo abaixo.
        </p>
        <button>Saiba mais</button>
      </div>
      <div class="seja-um-profissional__introduction__image">
        <img src="images/seja-um-profissional/introduction-image.png" alt="Pessoas na construção">
      </div>
    </section>
    <section class="seja-um-profissional__services">
      <h3>Confira nossas vantagens</h3>
      <div class="seja-um-profissional__services__cards">
        <app-medium-card
          *ngFor="let adv of advantages"
          [title]="adv.text"
          [image]="adv.image"
          [imageAlt]="adv.alt"
        />
      </div>
      <button>
        Seja Nosso MasterHub Pro
      </button>
    </section>
    <section class="seja-um-profissional__testimonials">
      <h3>O que os nossos usuários estão dizendo:</h3>
      <div class="seja-um-profissional__testimonials__carousel">
        <app-feedback-card
          *ngFor="let feedback of feedbacks"
          [name]="feedback.name"
          [photo]="feedback.photo"
          [feedback]="feedback.feedback"
        />
      </div>
    </section>
    <section class="seja-um-profissional__plans">
      <h3>Confira nossos planos:</h3>
      <div class="seja-um-profissional__plans__cards">
        <app-plan-card
          *ngFor="let plan of plans"
          [flag]="plan.flag"
          [photo]="plan.photo"
          [title]="plan.title"
          [price]="plan.price"
        />
      </div>
    </section>
    <section class="seja-um-profissional__faq">
      <h3>Perguntas frequentes:</h3>

      <div>
        <div
          *ngFor="let faq of faqs"
          class="seja-um-profissional__faq__question"
        >
          <h4>{{ faq.question }}</h4>
          <p>{{ faq.answer }}</p>
        </div>
      </div>
    </section>
  </div>
</main>
