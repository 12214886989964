import { SocialAuthService } from '@abacritt/angularx-social-login';
import { MediaMatcher } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, signal, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-header',
  imports: [
    MatIconModule,
    RouterModule,
    CommonModule,
    MatSidenavModule,
    MatListModule
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  protected readonly isMobile = signal(true);

  private readonly _mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;

  @ViewChild('snav') sidenav: MatSidenav | undefined;

  user: any;
  isLoggedIn:boolean = false;
  isProfileOpen = false;
  menuOpen = false;

  navItems: {label: string; link: string;}[] = [];

  currentRoute: string = '';

  isActive(route: string): boolean {
    return this.currentRoute === route;
  }

  handleInteractProfile() {
    this.isProfileOpen = !this.isProfileOpen;
  }

  handleLogout() {
    this.authService.logout();
    this.isLoggedIn = false;
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  closeMenu() {
    this.menuOpen = false;
    this.sidenav?.close();
  }

  constructor(private authService: AuthService, private router: Router, private gs: SocialAuthService) {
    this.router.events.subscribe(() => {
      this.currentRoute = this.router.url;
    });

    const media = inject(MediaMatcher);
    this._mobileQuery = media.matchMedia('(max-width: 900px)');
    this.isMobile.set(this._mobileQuery.matches);
    this._mobileQueryListener = () => this.isMobile.set(this._mobileQuery.matches);
    if (this._mobileQuery?.addEventListener) {
      this._mobileQuery.addEventListener('change', this._mobileQueryListener);
    } else {
      this._mobileQuery.addListener(this._mobileQueryListener);
    }

    this.authService.isAuthenticated().subscribe((state) => {
      this.isLoggedIn = state;
    });
    this.user = this.authService.getUser();
    this.navItems = [
      { label: 'Home', link: '/' },
      ...(this.isLoggedIn ?
        [
          { label: 'Meus cursos', link: '/meus-cursos' },
          { label: 'Suporte', link: '/suporte' },
          { label: 'Minhas franquias', link: '/minhas-franquias' }
        ] :
        [
          { label: 'Cursos', link: '/cursos' },
          { label: 'Seja um profissional', link: '/seja-um-profissional' },
          { label: 'Profissionais certificados', link: '/profissionais-certificados' },
        ]),
    ];
  }

  ngOnInit() {
    this.gs.authState.subscribe((user) => {
      this.isLoggedIn = user !== null;
      if (user) {
        this.authService.setAuthenticatedGoogleUser(user);
        this.router.navigate(['/']);
      }
    })
  };

  ngOnDestroy() {
    if (this._mobileQuery?.removeEventListener) {
      this._mobileQuery.removeEventListener('change', this._mobileQueryListener);
    } else {
      this._mobileQuery.removeListener(this._mobileQueryListener);
    }
  }
}
