<header class="header">
  @if (!isMobile()) {
    <div class="header-content">
      <img src="./logos/small-logo.svg" alt="Master Geohub logo">
          <mat-list role="list">
            <mat-list-item
              class="nav-item"
              *ngFor="let item of navItems"
              [class.active]="isActive(item.link)"
              [activated]="isActive(item.link)"
              role="listitem"
            >
              <a
                [routerLink]="item.link"
                class="nav-link"
                (click)="closeMenu()"
              >
                {{ item.label }}
              </a>
            </mat-list-item>
          </mat-list>
          @if (isLoggedIn) {
            <div class="user-actions">
              <div class="user-assets">
                <img src="assets/icons/notificacao.svg" alt="Notificação">
                <img src="assets/icons/shopping-cart.svg" alt="Carrinho">
              </div>

              <div class="user-info">
                <button (click)="handleInteractProfile()" class="button">
                  <img src="assets/icons/user.svg" alt="Usuário">
                  {{user?.firstName || user?.name}}
                  <img src="assets/icons/arrow-down-list.svg" alt="Seta para baixo">
                </button>
                <div *ngIf="isProfileOpen" class="logout" (click)="handleLogout()">
                  <img src="assets/icons/log-out.svg" alt="Sair">
                  Sair
                </div>
              </div>
            </div>
          } @else {
            <button class="button" routerLink="login">
              <img src="assets/icons/user.svg" alt="Perfil">
              ENTRAR
            </button>
          }
    </div>
  } @else {
    <mat-sidenav-container
      hasBackdrop="true"
      (backdropClick)="snav.close()"
    >
      <mat-sidenav
        #snav
        [mode]="isMobile() ? 'over':'side'"
        [opened]="menuOpen"
        [fixedInViewport]="true"
        role="navigation"
      >
        <mat-sidenav-content>
          <img src="./logos/small-logo.svg" alt="Master Geohub logo">
          <mat-list>
            <mat-list-item
              class="nav-item"
              *ngFor="let item of navItems"
              [class.active]="isActive(item.link)"
            >
              <a
                [routerLink]="item.link"
                class="nav-link"
                (click)="closeMenu()"

              >
                {{ item.label }}
              </a>
            </mat-list-item>
          </mat-list>
          @if (isLoggedIn) {
            <div class="user-actions">
              <div class="user-assets">
                <img src="assets/icons/notificacao.svg" alt="Notificação">
                <img src="assets/icons/shopping-cart.svg" alt="Carrinho">
              </div>

              <div class="user-info">
                <button (click)="handleInteractProfile()" class="button">
                  <img src="assets/icons/user.svg" alt="Usuário">
                  {{user?.firstName || user?.name}}
                  <img src="assets/icons/arrow-down-list.svg" alt="Seta para baixo">
                </button>
                <div *ngIf="isProfileOpen" class="logout" (click)="handleLogout()">
                  <img src="assets/icons/log-out.svg" alt="Sair">
                  Sair
                </div>
              </div>
            </div>
          } @else {
            <button class="button" routerLink="login" (click)="closeMenu()">
              <img src="assets/icons/user.svg" alt="Perfil">
              ENTRAR
            </button>
          }
        </mat-sidenav-content>
      </mat-sidenav>
    </mat-sidenav-container>
    <button class="menu-button" (click)="toggleMenu()">
      @if (menuOpen) {
        <mat-icon>close</mat-icon>
      } @else {
        <mat-icon>menu</mat-icon>
      }
    </button>
  }
</header>
