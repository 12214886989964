import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-professional-card',
  imports: [MatIconModule],
  templateUrl: './professional-card.component.html',
  styleUrl: './professional-card.component.scss'
})
export class ProfessionalCardComponent {
  @Input() professional: {
    photo: string;
    name: string;
    address: {
      city: string;
      state: string;
    },
    phone: string;
    email: string;
  } = {
    photo: '',
    name: '',
    address: {
      city: '',
      state: ''
    },
    phone: '',
    email: ''
  }
}
