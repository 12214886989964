<div class="professional-card__container">
  <div class="professional-card__image">
    <img [src]="professional.photo" [alt]="professional.name">
  </div>
  <div class="professional-card__info">
    <p class="professional-info__name">{{ professional.name }}</p>

    <div class="professional-info__details">
      <div class="professional-info__address professional-info">
        <mat-icon fontSet="material-icons-outlined">location_on</mat-icon>
        <span>{{ professional.address.city }}/{{professional.address.state}}</span>
      </div>
      <div class="professional-info__phone professional-info">
        <mat-icon fontSet="material-icons-outlined">call</mat-icon>
        <span>{{ professional.phone }}</span>
      </div>
      <div class="professional-info__email professional-info">
        <mat-icon fontSet="material-icons-outlined">email</mat-icon>
        <span>{{ professional.email }}</span>
      </div>
    </div>
  </div>
</div>
