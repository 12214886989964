<main class="cursos-container">
  <section class="banner-container">
    <div class="banner__pattern">
      <img src="images/courses/pattern.png" alt="Background Pattern">
    </div>
    <div class="banner__carousel">
      <img src="images/courses/carrosel.png" alt="Homens trabalhando">
    </div>
  </section>

  <div class="cursos-content">
    <section class="cursos__introduction">
      <h3>Nossos cursos</h3>
      <p>O curso de Métodos para Aprovação de Alvarás te ensina como navegar pelas exigências e garantir a liberação dos seus projetos com mais agilidade! </p>
      <div class="cursos-cards-container">
        <div
          class="cursos-card"
          *ngFor="let course of courses"
        >
          <img [src]="course.photo" [alt]="course.name">
          <div class="cursos-card__content">
            <h5>{{ course.name }}</h5>
            <span>{{ course.hours }} horas</span>
            <p>{{ course.description }}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="cursos__signup">
      <h3>Cadastre-se agora!</h3>
      <p>E torne-se já um profissional especializado em regulamentação de obras</p>
      <button>Cadastre-se</button>
    </section>

    <section class="cursos__plans">
      <h3>Opções de Planos ideais</h3>
      <div class="cursos-plans-container">
        <app-plan-card
          *ngFor="let plan of plans"
          [photo]="plan.photo"
          [title]="plan.name"
          [price]="plan.price"
          [flag]="plan.flag"
          buttonText="Assinar"
        />
      </div>
    </section>

    <section class="cursos__signup">
      <h3>Gostou de nossos planos?</h3>
      <button>Cadastre-se</button>
    </section>
  </div>
</main>
