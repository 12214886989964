import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-plan-card',
  imports: [CommonModule],
  templateUrl: './plan-card.component.html',
  styleUrl: './plan-card.component.scss'
})
export class PlanCardComponent {
  @Input() photo: string = '';
  @Input() title: string = '';
  @Input() price: number = 0;
  @Input() flag: string = '';
  @Input() buttonText?: string = '';
}
