import { Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { CadastroFranqueadoComponent } from './pages/cadastro-franqueado/cadastro-franqueado.component';
import { CursosComponent } from './pages/cursos/cursos.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { MeusCursosComponent } from './pages/meus-cursos/meus-cursos.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ProfissionaisCertificadosComponent } from './pages/profissionais-certificados/profissionais-certificados.component';
import { SejaUmProfissionalComponent } from './pages/seja-um-profissional/seja-um-profissional.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    title: 'Home - MasterHub',
  },
  {
    path: 'meus-cursos',
    component: MeusCursosComponent,
    title: 'Meus Cursos',
    canActivate: [AuthGuard]
  },
  {
    path: 'profissionais-certificados',
    component: ProfissionaisCertificadosComponent,
    title: 'Profissionais Certificados',
  },
  {
    path: 'cursos',
    component: CursosComponent,
    title: 'Cursos',
  },
  {
    path: 'seja-um-profissional',
    component: SejaUmProfissionalComponent,
    title: 'Seja um Profissional',
  },
  {
    title: 'Cadastro',
    path: 'cadastro',
    children: [
      {
        component: CadastroFranqueadoComponent,
        path: 'profissional',
        title: 'Cadastro - Profissional'
      }
    ]
  },
  {
    title: 'Login',
    path: 'login',
    component: LoginComponent,
  },
  {
    title: 'Página não encontrada',
    path: '**',
    component: NotFoundComponent,
  }
];
