import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ProfessionalCardComponent } from "../../components/card/professional-card/professional-card.component";
import { ComponentsModule } from '../../components/components.module';

@Component({
  selector: 'app-profissionais-certificados',
  imports: [
    ProfessionalCardComponent,
    ComponentsModule,
    CommonModule,
    RouterModule
  ],
  templateUrl: './profissionais-certificados.component.html',
  styleUrl: './profissionais-certificados.component.scss'
})
export class ProfissionaisCertificadosComponent implements OnInit {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(params => {
      const search = params.get('search') ?
        params.get('search') ? params.get('search')!.toLowerCase()
        : '' : '';

      this.filteredProfessionals = this.allProfessionals.filter(professional => {
        return professional.name.toLowerCase().includes(search);
      });
    });

  }

  allProfessionals = [
    {
      photo: 'images/profissionais-certificados/default.png',
      name: 'Marcos Silva',
      address: {
        city: 'São Paulo',
        state: 'São Paulo'
      },
      phone: '(11) 99999-9999',
      email: 'exemplo@exemplo.com'
    },
    {
      photo: 'images/profissionais-certificados/default.png',
      name: 'Marcos Silva',
      address: {
        city: 'São Paulo',
        state: 'São Paulo'
      },
      phone: '(11) 99999-9999',
      email: 'exemplo@exemplo.com'
    },
    {
      photo: 'images/profissionais-certificados/default.png',
      name: 'Marcos Silva',
      address: {
        city: 'São Paulo',
        state: 'São Paulo'
      },
      phone: '(11) 99999-9999',
      email: 'exemplo@exemplo.com'
    },
    {
      photo: 'images/profissionais-certificados/default.png',
      name: 'Marcos Silva',
      address: {
        city: 'São Paulo',
        state: 'São Paulo'
      },
      phone: '(11) 99999-9999',
      email: 'exemplo@exemplo.com'
    },
    {
      photo: 'images/profissionais-certificados/default.png',
      name: 'Marcos Silva',
      address: {
        city: 'São Paulo',
        state: 'São Paulo'
      },
      phone: '(11) 99999-9999',
      email: 'exemplo@exemplo.com'
    },
    {
      photo: 'images/profissionais-certificados/default.png',
      name: 'Marcos Silva',
      address: {
        city: 'São Paulo',
        state: 'São Paulo'
      },
      phone: '(11) 99999-9999',
      email: 'exemplo@exemplo.com'
    },
  ];

  filteredProfessionals = this.allProfessionals;

  querySearch = '';

  searchProfessional(event: any) {
    this.querySearch = event.target.value;
  };

  handleFilter() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { search: this.querySearch }
    });
  }
}
