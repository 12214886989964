<main class="not-found-container">
  <div class="not-found-content">
    <h1 class="not-found-title">
      Página não encontrada
    </h1>
    <p class="not-found-description">
      A página que você está procurando não existe ou foi removida.
    </p>

    <a routerLink="/" class="router-link">
      Voltar para a página inicial
    </a>

    <div class="not-found-illustration">
      <img
        src="images/not-found.png"
        alt="Not found illustration"
      >
    </div>
  </div>
</main>
